<template>

  <main class="podcast-list">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>

          <router-link to="/podcasty" title="Podcasty" class="breadcrumb">
            Podcasty
          </router-link>
        </div>
      </div>


      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Podcasty</h1>
        </div>
      </div>


      <div class="row">
        <div class="col s12">
          <div class="filter">

            <div class="row mb-0 row-db">
              <div class="col s12 l4">

                <div class="filter-block">
                  <input name="id_user" type="hidden" v-model="id_user">
                  <div class="filter-block-head" v-on:click="filterHeadClick">Prowadzący</div>
                  <ul class="filter-block-body">
                    <li class="filter-block-body-item" v-for="user in users"
                        :class="{ 'filter-block-body-item-selected': (id_user === user.id)  }"
                        :data-value="user.id"
                        v-on:click="filterItemClick"
                    >
                      <div class="filter-block-body-item-thumb"
                           :style="{ backgroundImage: `url(${user.avatar_url})` }"></div>
                      <h4 class="filter-block-body-item-name">{{ user.firstname }} {{ user.lastname }}</h4>
                      <span class="filter-block-body-item-position">{{ user.position }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col s12 l4">

                <div class="filter-block">
                  <input name="id_family" type="hidden" v-model="id_family">
                  <div class="filter-block-head" v-on:click="filterHeadClick">Audycje</div>
                  <ul class="filter-block-body">

                    <li class="filter-block-body-item" v-for="family in familys"
                        :class="{ 'filter-block-body-item-selected': (id_family === family.id)  }"
                        :data-value="family.id"
                        v-on:click="filterItemClick"
                    >
                      <div class="filter-block-body-item-thumb"
                           :style="{ backgroundImage: `url(${family.thumb})` }"></div>
                      <h4 class="filter-block-body-item-name-full">{{ family.title }}</h4>
                    </li>

                  </ul>
                </div>
              </div>
              <div class="col s12 l4">

                <div class="filter-block search-text">
                  <input name="has_text" type="text" class="search-text__input" v-model="has_text"
                         placeholder="Wpisz czego szukasz">
                  <svg class="search-text__icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                       viewBox="0 0 490 490"

                  >
                    <path fill="none" stroke="#fff" stroke-width="42" stroke-linecap="round"
                          d="m280,278a153,153 0 1,0-2,2l170,170m-91-117 110,110-26,26-110-110"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="row" :class="{loading: stateCompare('loading')}">
        <div class="col s12">

          <div class="card color darken-1" v-if="articles && articles.length == 0">
            <div class="card-content white-text">
              <p>Brak</p>
            </div>
          </div>

          <PodcastLine v-for="article in articles" :article="article"/>

          <Pagination :page="page" :count="count"/>

        </div>
      </div>


    </div>
  </main>
  <SectionInfo code="broadcastlist"/>
</template>

<script>
import axios from "@/axios2";
import PodcastLine from "@/views/Audio/Part/PodcastLine";
import Pagination from "@/components/Pagination";
import CoreArray from "@/helpers/array.js";
import ComponentListStateMachine from "../../Extends/ComponentListStateMachine";
import SectionInfo from "../Home/SectionInfo";

export default {
  name: "PodcastList",
  components: {SectionInfo, Pagination, PodcastLine},
  extends: ComponentListStateMachine,
  data() {
    return {
      'id_user': null,
      'id_family': null,
      'has_text': "",
      'users': null,
      'familys': null,
      'page': 1,
      'count': null,
      'articles': null,
    }
  },
  methods: {
    filterItemClick: function (e, e2) {

      let item = e.target.parentNode;
      let id = item.dataset.value;

      if (item.classList.contains('filter-block-body-item-selected')) {
        id = null;
      }

      let block = e.target.parentNode.parentNode.parentNode
      block.classList.toggle("filter-block-open");

      let name = block.querySelector('input').name;

      if (name === 'id_family')
        this.id_family = id;

      if (name === 'id_user')
        this.id_user = id;

      this.refresh();


    },
    filterHeadClick: function (e) {
      let block = e.target.parentNode
      block.classList.toggle("filter-block-open");
    },
    refresh: function () {

      let domain = "https://radiokrakowkultura.pl/api/"
      let domain_new = "https://radiokrakowkultura.pl/"

      this.stateUpdate('sendInfo');

      axios
          .get('article/get', {
            params: {
              scope: 'title,block_thumb,thumb,url,date,audio,audio_url,audio_time,text,thumb_url',
              'type': 'broadcast',
              'id_user_only': this.id_user,
              'id_family': this.id_family,
              'has_text': this.has_text,
              'page': this.page,
              'count': 20,
              'is_show': true
            }
          })
          .then(response => {

            let articles = response.data.data;
            // articles = CoreArray.changeDomain(articles,'thumb',domain,domain_new);
            // articles = CoreArray.changeDomain(articles,'block_thumb',domain,domain_new);
            // articles = CoreArray.changeDomain(articles,'audio_url',domain,domain_new);
            articles = CoreArray.changeDomain(articles, 'url', 'audycje', 'podcasty');

            this.count = response.data.count;
            this.articles = articles;

            this.stateUpdate('sendSuccess');
          })
          .catch(error => {

            this.stateUpdate('sendWrong');
            this.refresh();

          });
    }
  },
  created() {

    let domain = "https://off.radiokrakow.pl/api/"
    let domain_new = "https://off.radiokrakow.pl/"

    axios
        .get('user/get', {
          params: {
            scope: 'id,firstname,lastname,name,avatar_url,url_full',
            sort: 'lastname',
            count: 100,
            show_on_list: 'yes',
            role: 'editor',
            id_NOTIN: '1935,749',
          }
        })
        .then(response => {

          this.users = response.data.data;
          this.users = CoreArray.changeDomain(this.users, 'avatar_url', domain, domain_new);

        });

    axios
        .get('articlefamily/get', {params: {scope: 'thumb,title,url,id', sort: 'title'}})
        .then(response => {

          this.familys = response.data.data;
          this.familys = CoreArray.changeDomain(this.familys, 'thumb', domain, domain_new);

        })

    this.refresh();

  },

  watch: {
    page: function (val) {
      this.refresh();
    },
    has_text: function (val) {
      this.refresh();
    },
  },

}
</script>

<style scoped lang="scss">
.search-text {

  padding: 15px 12px;
  position: relative;
  &__icon{
    position: absolute;
    right: 24px;
    top: 29px;

  }
  &__input,
  &__input:focus {
    color: #fff;
    text-transform: uppercase;
    font-size: 1.3rem !important;
    border: none !important;
    font-family: Joane;

    box-shadow: none !important;
    outline: none;

  }
}

</style>